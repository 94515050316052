














import Validation from "@/validation";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import CMoney from "./CMoney.vue";
@Component({
    components: {
        CMoney,
    },
})
export default class CMoneyAdjuster extends Vue {
    protected Validation = Validation;

    @Prop({
        type: Boolean,
    })
    protected allowPositiveValueOnly!: boolean;
    protected isValid = !this.allowPositiveValueOnly;

    @Prop({
        type: Number,
    })
    protected initialValue!: number;

    @Prop({
        type: String,
    })
    public title!: number;

    protected newValue = this.initialValue;
    protected rules = this.allowPositiveValueOnly ? [Validation.requireValueNonNegative] : [];

    private close(accepted: boolean) {
        this.$emit("close", accepted, this.newValue);
    }
}
