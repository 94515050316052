import OrderPaymentViewModel from "@/chipply/ecom-orders/OrderPaymentViewModel";

export default class OrderSetupViewModel extends OrderPaymentViewModel {
    public async accept() {
        this.loading = true;
        const { error: stripeConfirmationError } = await this.stripe!.confirmSetup({
            elements: this.stripeElementsInstance!,
            confirmParams: {
                return_url: `${location.origin}/ng/edit-order.html?oid=${this.order?.orderNumber}&flow=refund`,
            },
        });

        this.loading = false;
        if (stripeConfirmationError) return;

        this.done("accept");
    }
}
