





















import TaxTypeSelection from "@/components/products/TaxTypeSelection.vue";
import CMoney from "@/components/ui/CMoney.vue";
import Validation from "@/validation";
import { IOrderAdjustment } from "chipply-common";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
    components: {
        TaxTypeSelection,
        CMoney,
    },
})
export default class AddAdjustment extends Vue {
    protected Validation = Validation;

    protected isValid = false;

    @Prop({
        type: Object,
    })
    protected adjustment!: IOrderAdjustment;
}
