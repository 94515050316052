


































import { AsyncInteractionViewModel, AsyncInteractionResult } from "chipply-common";
import Component from "vue-class-component";
import Vue from "vue";
import { Watch, Prop } from "vue-property-decorator";

@Component({})
export default class ConfirmVoidDialog extends Vue {
    @Prop({
        type: Boolean,
    })
    public show!: boolean;
    public showConfirmation = false;
    public showDoubleConfirmation = false;

    @Prop({
        type: Object,
    })
    public viewModel!: AsyncInteractionViewModel;

    public created() {
        if (this.show) {
            this.showConfirmation = true;
        }
    }

    @Watch("show")
    public onShowChanged() {
        if (this.show) {
            this.showConfirmation = true;
        }
    }

    public noVoidConfirmation() {
        this.showConfirmation = false;
        this.showDoubleConfirmation = true;
    }

    public finish(result: AsyncInteractionResult) {
        this.showConfirmation = false;
        this.showDoubleConfirmation = false;
        this.viewModel.done(result);
    }
}
