import OrderViewModel from "@/chipply/ecom-orders/OrderViewModel";
import IStoreShipping from "@/chipply/event/IStoreShipping";
import { ShippingType, ShippingTypeHelper } from "chipply-common";
import { Address, AsyncInteractionWithDataViewModel, WebHelper } from "chipply-common";

export default class OrderShippingViewModel extends AsyncInteractionWithDataViewModel<any> {
    public order!: OrderViewModel | null;
    public shippingType: ShippingType | null = null;
    public inStoreBranch: number | null = null;
    public shippingAmount = 0;
    public storeShipping: IStoreShipping | null = null;
    public shippingAddress: Address = new Address();
    public organizationBranchId = 0;
    public useBillingAddress = true;

    public cancel() {
        this.done("cancel");
    }

    public accept() {
        this.done("accept", {
            address: this.shippingAddress,
            branchId: this.shippingType === ShippingType.InStorePickup ? this.inStoreBranch : null,
            organizationBranchId: this.shippingType === ShippingType.GroupPickup ? this.organizationBranchId : null,
            shippingAmount: this.shippingAmount,
            shippingType: ShippingTypeHelper.toString(this.shippingType!),
            shippingTypeEnum: this.shippingType,
            useBillingAddress: this.useBillingAddress,
        });
    }

    public async initialize() {
        this.shippingAmount = this.order!.totals.shippingTotal;
        this.shippingType = this.order!.shippingType
            ? ShippingTypeHelper.toShippingType(this.order!.shippingType)
            : null;
        this.inStoreBranch = this.order!.pickupBranchId;
        this.organizationBranchId = this.order!.organizationBranchId;

        const orderData = await WebHelper.getJsonData(
            `/api/EcomOrder/GetOrderShipping/${this.order!.orderNumber}`,
            true
        );
        this.storeShipping = orderData.shipping;
        this.shippingAddress = new Address(this.order!.shippingAddress);
    }
}
