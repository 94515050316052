import { TaxType } from "chipply-common";
import { IOrderAdjustment } from "chipply-common";

export default class OrderAdjustment implements IOrderAdjustment {
    public amount = 0;
    public description = "";
    public fundraising = 0;
    public isPayLater = false;
    public isRefundLater = false;
    public eventProductId = 0;

    public get name() {
        return this.description;
    }

    public orderItemId = 0;
    public processCost = 0;

    public get productPrice() {
        return this.amount;
    }

    public qty = 1;
    public taxRate: number | null = null;
    public taxType: TaxType = TaxType.Auto;
}
