
















import CouponCodeSelectionViewModel from "@/chipply/ecom-orders/CouponCodeSelectionViewModel";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";

@Component
export default class CouponCodeDialog extends Vue {
    @Prop()
    public viewModel!: CouponCodeSelectionViewModel | null;

    @Prop()
    public show!: boolean;
}
