
















































































import OrderShippingViewModel from "@/chipply/ecom-orders/OrderShippingViewModel";
import { ShippingType, ShippingTypeHelper } from "chipply-common";
import FormValidationMixin from "@/components/FormValidationMixin";
import OrderAddressEditor from "@/components/orders/OrderAddressEditor.vue";
import CMoney from "@/components/ui/CMoney.vue";
import Validation from "@/validation";
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component({
    components: {
        AddressEditor: OrderAddressEditor,
        CMoney,
    },
})
export default class OrderShipping extends mixins(FormValidationMixin) {
    public rules = [Validation.requireValueNonNegative];

    @Prop({
        type: Object,
    })
    public vm!: OrderShippingViewModel | null;

    protected Validation = Validation;

    protected ShippingType = ShippingType;

    public async created() {
        await this.initialize();
    }

    public onShippingChanged() {
        this.revalidate();
    }

    @Watch("vm")
    protected onViewModelChanged() {
        this.initialize();
    }

    protected async initialize() {
        if (!this.vm) {
            return;
        }
        await this.vm!.initialize();
    }

    private requireInStoreSelection() {
        if (!this.vm) {
            return true;
        }

        if (this.vm.shippingType !== ShippingType.InStorePickup) {
            return true;
        }
        return this.vm.inStoreBranch ? true : "You must select a pickup location";
    }

    private revalidate() {
        this.$nextTick(() => {
            (this.$refs.form as any).validate();
        });
    }
}
