













import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import chipplyIcons from "../../chipply/ImportIcons";

@Component({})
export default class RefundMoreThanAvailableDialog extends Vue {
    public chipplyIcons = chipplyIcons;

    @Prop({
        type: Boolean,
    })
    public show!: boolean;

    @Prop({
        type: Object,
    })
    public viewModel!: object;
}
