














































































import EditLayout from "@/components/layout/EditLayout.vue";
import CDecisionCard from "@/components/ui/CDecisionCard.vue";
import CToolbarBtn from "@/components/ui/CToolbarBtn.vue";
import TextHeading from "@/components/utility/TextHeading.vue";
import { StripePaymentElement } from "@stripe/stripe-js";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import OrderPaymentViewModel from "../../chipply/ecom-orders/OrderPaymentViewModel";
import { CCreditCard } from "chipply-common-vue";

@Component({
    components: {
        CDecisionCard,
        CToolbarBtn,
        EditLayout,
        CCreditCard,
        TextHeading,
    },
})
export default class OrderPayment extends Vue {
    public isValid = false;

    public $refs!: {
        creditCardContainer: HTMLDivElement;
    };

    @Prop()
    public viewModel!: OrderPaymentViewModel | null;
    @Prop({ type: Boolean })
    public hideProcessLater!: boolean;
    @Prop({ type: Boolean })
    public hideCancel!: boolean;
    @Prop({ type: String })
    public processLaterLabel!: string;
    @Prop({ type: String })
    public acceptLabel!: string;

    public paymentElement: StripePaymentElement | null | undefined = null;
    public previouslyMounted = false;
    public mounted() {
        if (this.previouslyMounted) {
            return;
        }

        this.$nextTick(() => {
            if (!this.$refs.creditCardContainer) {
                return;
            }

            this.paymentElement = this.viewModel!.stripeElementsInstance!.create("payment");
            this.paymentElement!.mount(this.$refs.creditCardContainer);
        });
        this.previouslyMounted = true;
    }
}
