

























































































































































































































































































































































































































































import CouponCodeSelectionViewModel from "@/chipply/ecom-orders/CouponCodeSelectionViewModel";
import IGetOrderTotalsArgs from "@/chipply/ecom-orders/IGetOrderTotalsArgs";
import OrderAdjustmentViewModel from "@/chipply/ecom-orders/OrderAdjustmentViewModel";
import OrderItemViewModel from "@/chipply/ecom-orders/OrderItemViewModel";
import OrderSetupViewModel from "@/chipply/ecom-orders/OrderSetupViewModel";
import OrderShippingViewModel from "@/chipply/ecom-orders/OrderShippingViewModel";
import { OrderSuccessFailureViewModel } from "@/chipply/ecom-orders/OrderSuccessFailureViewModel";
import OrderTotalsViewModel from "@/chipply/ecom-orders/OrderTotalsViewModel";
import OrderViewModel from "@/chipply/ecom-orders/OrderViewModel";
import IStoreShipping from "@/chipply/event/IStoreShipping";
import ITransactionResponse from "@/chipply/IPaymentResult";
import { ShippingType } from "chipply-common";
import { ITextValueDisabled } from "chipply-common";
import CouponCodeDialog from "@/components/orders/CouponCodeDialog.vue";
import OrderShipping from "@/components/orders/OrderShipping.vue";
import OrderAdjustment from "@/chipply/ecom-orders/OrderAdjustment";
import OrderPaymentViewModel from "@/chipply/ecom-orders/OrderPaymentViewModel";
import IAsyncEventArgs from "@/chipply/event/IAsyncEventArgs";
import { EventBus } from "@/chipply/EventBus";
import chipplyIcons from "@/chipply/ImportIcons";
import IPaymentResult from "@/chipply/IPaymentResult";
import ConfirmRefundDialogViewModel from "@/chipply/view-model/ConfirmRefundDialogViewModel";
import CSaveDialog from "@/components/dialogs/CSaveDialog.vue";
import ProductsSelector from "@/components/events/ProductsSelector.vue";
import AddAdjustment from "@/components/orders/AddAdjustment.vue";
import ConfirmRefundDialog from "@/components/orders/ConfirmRefundDialog.vue";
import ConfirmVoidDialog from "@/components/orders/ConfirmVoidDialog.vue";
import EditOrderItem from "@/components/orders/EditOrderItem.vue";
import EditOrderTaxSettings from "@/components/orders/EditOrderTaxSettings.vue";
import OrderPayment from "@/components/orders/OrderPayment.vue";
import RefundMoreThanAvailableDialog from "@/components/orders/RefundMoreThanAvailableDialog.vue";
import TaxTypeSelection from "@/components/products/TaxTypeSelection.vue";
import CAddButton from "@/components/ui/CAddButton.vue";
import CControlLabel from "@/components/ui/CControlLabel.vue";
import CDecisionCard from "@/components/ui/CDecisionCard.vue";
import CDeleteButton from "@/components/ui/CDeleteButton.vue";
import CEditButton from "@/components/ui/CEditButton.vue";
import CMoney from "@/components/ui/CMoney.vue";
import CMoneyAdjuster from "@/components/ui/CMoneyAdjuster.vue";
import CSaveButton from "@/components/ui/CSaveButton.vue";
import CSuccessOrFailureCard from "@/components/ui/CSuccessOrFailureCard.vue";
import LongRunningOperationDialog from "@/components/utility/LongRunningOperationDialog.vue";
import TextHeading from "@/components/utility/TextHeading.vue";
import { CCreditCard, CDismissDialog } from "chipply-common-vue";
import _, { reduce } from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import {
    AsyncInteractionViewModel,
    AsyncInteractionWithDataViewModel,
    ICreditCardInfo,
    IOrder,
    IOrderItem,
    IOrderTax,
    IOrderTotals,
    SimpleAsyncInteractionViewModel,
    Utils,
    WebHelper,
} from "chipply-common";
import { Prop, Watch } from "vue-property-decorator";

enum View {
    Order,
    AddProduct,
    EditProduct,
    RefundCard,
    ChargeCard,
    RefundFullCard,
}

@Component({
    components: {
        CSuccessOrFailureCard,
        CDecisionCard,
        CDismissDialog,
        CouponCodeDialog,
        AddAdjustment,
        CAddButton,
        CControlLabel,
        CCreditCard,
        CDeleteButton,
        CEditButton,
        CMoney,
        CMoneyAdjuster,
        CSaveButton,
        CSaveDialog,
        ConfirmRefundDialog,
        ConfirmVoidDialog,
        EditOrderItem,
        EditOrderTaxSettings,
        LongRunningOperationDialog,
        OrderPayment,
        OrderShipping,
        ProductsSelector,
        RefundMoreThanAvailableDialog,
        TaxTypeSelection,
        TextHeading,
    },
})
export default class EditOrder extends Vue {
    @Prop({ type: String })
    public flow!: string | undefined;

    @Prop({ type: Boolean, default: false })
    public shouldScopeToCatalogBatch!: boolean;

    @Prop({ type: Boolean })
    public isAdmin!: boolean;

    @Watch("currentView")
    onCurrentViewChanged(newView: View) {
        this.$emit("update:currentView", newView);
    }

    public get showOrderSuccessFailure() {
        return this.orderSuccessFailureViewModel != null;
    }

    get orderTotalDifference() {
        return (this.order?.totals.orderTotal ?? 0) - (this.order?.originalTotals.orderTotal ?? 0);
    }

    protected get hasChanges() {
        const currentOrder = this.order!.toModel();
        return !_.isEqual(this.originalOrder, currentOrder);
    }
    public get showShipping() {
        return this.shippingViewModel !== null;
    }

    protected get totalDueDifference() {
        const cardDifference = this.cardDifference;
        const isEcertOrder = this.isEcertOrder;
        const isPromoOrder = this.isPromoOrder;

        if (isEcertOrder) {
            return 0;
        }
        if (isPromoOrder) {
            return 0;
        }

        return cardDifference;
    }

    protected get cardDifference() {
        return this.order!.totals.cardTotal - this.order!.originalTotals.cardTotal;
    }

    protected get isEcertOrder() {
        return (
            this.order!.totals.orderTotal === this.order!.totals.ecertTotal &&
            this.order!.originalTotals.orderTotal === this.order!.originalTotals.ecertTotal
        );
    }

    protected get isPromoOrder() {
        return (
            this.order!.totals.orderTotal === this.order!.totals.couponTotal &&
            this.order!.originalTotals.orderTotal === this.order!.originalTotals.couponTotal
        );
    }

    public get showPayment() {
        return this.totalDueDifference > 0;
    }

    public get showRefund() {
        const voided = this.order!.isVoided;
        const isComplete = this.order!.isComplete;
        const hasRefundLater = this.order!.hasRefundLaterAdjustment;
        const negativeBalance = this.orderTotalDifference < 0;
        const cardRefundDue = this.totalDueDifference < 0;
        if (!isComplete) {
            return false;
        }
        if (voided) {
            return false;
        }
        if (hasRefundLater) {
            return false;
        }
        if (negativeBalance) {
            return true;
        }
        if (cardRefundDue) {
            return true;
        }

        return !this.hasChanges;
    }

    public orderSuccessFailureViewModel: OrderSuccessFailureViewModel | null = null;

    public chipplyIcons = chipplyIcons;
    public Utils = Utils;
    public View = View;

    public loading = false;
    public currentView = View.Order;
    public confirmViewModel: AsyncInteractionViewModel | null = null;
    public confirmRefundViewModel: ConfirmRefundDialogViewModel | null = null;
    public confirmVoidViewModel: AsyncInteractionViewModel | null = null;
    public couponCodeViewModel: CouponCodeSelectionViewModel | null = null;
    public decisionDialogViewModel: SimpleAsyncInteractionViewModel | null = null;
    public showDecisionDialogViewModel = false;
    public refundMoreThanAvailableViewModel: AsyncInteractionViewModel | null = null;
    public shippingViewModel: OrderShippingViewModel | null = null;
    public emailConfirmationViewModel: SimpleAsyncInteractionViewModel | null = null;
    public deleteChargebackViewModel: SimpleAsyncInteractionViewModel | null = null;

    public showOrderValidationFailure = false;
    public showVoidOrder = false;
    public showAdjustment = false;
    public showAdjustHandling = false;
    public showTaxSettings = false;
    public statusMessage: string | null = null;
    public clientDateTime: string | null = null;

    public showCustomerEmailConfirmation = false;
    public showDeleteChargebackDialog = false;
    public shouldVoid = false;
    public showDismiss = true;

    public couponCodes: ITextValueDisabled<number>[] | null = null;

    public editingOrderTax: IOrderTax | null = null;
    public editingOrderItem: OrderItemViewModel | null = null;
    public editingAdjustment: OrderAdjustmentViewModel | null = null;
    public paymentViewModel: OrderPaymentViewModel | null = null;

    public originalOrder: IOrder | null = null;

    @Prop({
        type: Number,
    })
    public orderId!: number | null;

    @Prop({
        type: Boolean,
        default: false,
    })
    public isInChildPane!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    public isChargeback!: boolean;

    public order: OrderViewModel | null = null;

    public headers = [
        {
            sortable: false,
            text: "",
            value: "edit",
            width: "60px",
        },
        {
            sortable: false,
            text: "",
            value: "image",
            width: "60px",
        },
        {
            sortable: false,
            text: "Name/Style",
            value: "namestyle",
        },
        {
            sortable: false,
            text: "Size/Color",
            value: "sizecolor",
        },
        {
            sortable: false,
            text: "Options",
            value: "options",
        },
        {
            sortable: false,
            text: "Quantity",
            value: "qty",
        },
        {
            sortable: false,
            text: "Price",
            value: "price",
        },
        {
            sortable: false,
            text: "Total",
            value: "total",
        },
        {
            sortable: false,
            text: "",
            value: "delete",
        },
    ];

    protected paymentFailure: IPaymentResult | null = null;
    protected card: ICreditCardInfo = {
        cardNumber: "",
        cardholderName: "",
        expirationMmYy: "",
        securityCode: "",
    };

    public async created() {
        EventBus.$on("top-navigation-started", (location: string, eventArgs: IAsyncEventArgs) => {
            if (!this.hasChanges) {
                return;
            }

            const promise = new Promise(async (resolve) => {
                this.confirmViewModel = new AsyncInteractionViewModel();
                const result = await this.confirmViewModel!.interact();
                if (result === "cancel") {
                    eventArgs.cancel = true;
                    resolve(eventArgs.cancel);
                    this.confirmViewModel = null;
                } else if (result === "continue") {
                    resolve(false);
                }
            });
            eventArgs.promises.push(promise);
        });

        await this.initialize();

        if (this.flow) {
            await this.showSuccess(true);
            if (!this.order?.isComplete) {
                await this.sendConfirmationEmail();
            }
            this.navigateToOrderManager();
        }

        document.addEventListener("visibilitychange", async () => {
            if (document.visibilityState === "hidden") {
                fetch(`/api/EcomOrder/RemoveLock/${this.orderId}`, {
                    method: "GET",
                    keepalive: true,
                });
            } else {
                let results = await WebHelper.getJsonData(`/api/EcomOrder/LockOrder/${this.orderId}`);
                this.redirectIfNoLock(results);
            }
        });
    }

    public beforeDestroy() {
        EventBus.$off("top-navigation-started");
    }

    public async initialize() {
        if (!this.orderId) {
            return;
        }

        await this.reloadOrder();
    }

    public async editCoupon() {
        const vm = new CouponCodeSelectionViewModel();

        vm.selectedId = this.order!.couponId;
        vm.couponCodes = this.couponCodes!;
        this.couponCodeViewModel = vm;
        const result = await vm.interact();
        if (result === "cancel") {
            this.couponCodeViewModel = null;
            return;
        }

        this.order!.couponId = vm.selectedId;

        if (!vm.selectedId || vm.selectedId == 0) {
            this.order!.couponId = null;
            this.order!.couponCode = null;
        }

        await this.reloadOrderTotals();

        for (const item of this.couponCodes!) {
            if (item.value !== vm.selectedId) {
                continue;
            }
            this.order!.couponCode = item.text;
        }

        this.couponCodeViewModel = null;
    }
    protected orderValidationFailureDismissed() {
        this.reset();
    }

    protected closeAlert() {
        this.showDismiss = !this.showDismiss;
    }

    protected redirectIfNoLock(lockInfo: any) {
        if (!lockInfo.successful) {
            location.assign("/ng/ecom-orders.html?errorMessage=" + encodeURIComponent(lockInfo.failureReason));
            return true;
        }
        return false;
    }

    protected async reloadOrder() {
        if (this.flow == "refund") {
            await WebHelper.postJsonData(`/api/EcomOrder/ManualRefund`, { orderId: this.orderId });
        }

        const orderData = await WebHelper.getJsonData(`/api/EcomOrder/GetOrder/${this.orderId}/${this.flow}`, true);
        this.clientDateTime = orderData.serverDateTimeOffset;
        const originalViewModel = new OrderViewModel(orderData.order);
        this.originalOrder = originalViewModel.toModel();
        if (this.redirectIfNoLock(orderData.lockInfo)) {
            return;
        }

        this.couponCodes = orderData.couponCodes;
        this.order = new OrderViewModel(orderData.order);
        if (orderData.order.forceTransaction) {
            if (orderData.order.failedAmount < 0) {
                await this.refundOrder(orderData.order.failedAmount, true, orderData.transactionSetup);
            }
        }
    }

    protected async saveChanges() {
        this.statusMessage = "Saving...";
        await WebHelper.postJsonData("/api/EcomOrder/ApplyChanges", this.order!.toModel());
        await this.reloadOrder();
        this.statusMessage = null;
    }

    protected async reset() {
        window.location.reload();
    }

    protected async deleteAdjustment(adj: OrderAdjustmentViewModel) {
        const removeIndex = this.order!.adjustments.indexOf(adj);
        this.order!.adjustments.splice(removeIndex, 1);
        await this.reloadOrderTotals();
    }

    protected async editAdjustment(adj: OrderAdjustmentViewModel) {
        this.editingAdjustment = adj.createEditViewModel();

        this.showAdjustment = true;
        const editResult = await this.editingAdjustment.edit();

        if (!editResult.canceled) {
            adj.apply(this.editingAdjustment.toModel());
            await this.reloadOrderTotals();
        }

        this.showAdjustment = false;
    }

    protected async addProductClosed(accepted: boolean, selectedEventProductId: number) {
        if (!accepted) {
            this.currentView = View.Order;
            return;
        }

        const results = (await WebHelper.getJsonData(
            `/api/EcomOrder/GetOrderItem/${this.orderId}` + `/${selectedEventProductId}`,
            true
        )) as IOrderItem;

        this.editingOrderItem = new OrderItemViewModel(results);
        this.currentView = View.EditProduct;
        const editResult = await this.editingOrderItem.edit();

        if (!editResult.canceled) {
            this.order!.products.push(this.editingOrderItem);
        } else {
            this.currentView = View.AddProduct;
            return;
        }

        this.currentView = View.Order;
        await this.reloadOrderTotals();

        if (this.order?.couponCode) {
            await this.reloadOrderTotals();
        }
    }

    protected async reloadOrderTotals() {
        this.statusMessage = "Refreshing...";

        const args: IGetOrderTotalsArgs = {
            order: this.order!.toModel(),
        };

        const results = await WebHelper.postJsonData("/api/EcomOrder/CalculateOrderTotals", args);
        const totals = JSON.parse(results) as IOrderTotals;
        this.order!.totals = new OrderTotalsViewModel(totals);
        this.statusMessage = null;
    }

    protected addProduct() {
        this.currentView = View.AddProduct;
    }

    protected async deleteProduct(item: OrderItemViewModel) {
        const index = this.order!.products.indexOf(item);
        this.order!.products.splice(index, 1);
        await this.reloadOrderTotals();
    }

    protected async editProduct(item: OrderItemViewModel) {
        const editVm = item.createEditViewModel();
        this.currentView = View.EditProduct;
        const editingItem = item;
        this.editingOrderItem = editVm;
        const editResult = await editVm.edit();

        if (!editResult.canceled) {
            editingItem.apply(editVm);
        }

        this.currentView = View.Order;
        await this.reloadOrderTotals();

        // Kind of goofy but if the coupon could be for an amount greater than presently applied, we need to call this twice
        // after the we refresh the new order total for it to apply. Perhaps we could track the coupon amounts on the client
        // to avoid this?
        if (this.order?.couponCode) {
            await this.reloadOrderTotals();
        }
    }

    protected async addAdjustment() {
        this.editingAdjustment = new OrderAdjustmentViewModel(new OrderAdjustment());

        this.showAdjustment = true;
        const editResult = await this.editingAdjustment.edit();

        if (!editResult.canceled) {
            this.order!.adjustments.push(this.editingAdjustment);
            await this.reloadOrderTotals();
        }

        this.showAdjustment = false;
    }
    protected async showFailure(paymentResult: IPaymentResult, skipDialog?: boolean) {
        if (!skipDialog) {
            this.orderSuccessFailureViewModel = new OrderSuccessFailureViewModel();
            this.orderSuccessFailureViewModel.data = false;
            this.orderSuccessFailureViewModel.paymentFailure = paymentResult;
            await this.orderSuccessFailureViewModel.interact();
            this.orderSuccessFailureViewModel = null;
        }
    }

    protected async showSuccess(cancelNavigation?: boolean) {
        this.orderSuccessFailureViewModel = new OrderSuccessFailureViewModel();
        this.orderSuccessFailureViewModel.data = true;
        await this.orderSuccessFailureViewModel.interact();
        this.orderSuccessFailureViewModel = null;
        if (!cancelNavigation) {
            this.navigateToOrderManager();
        }
    }

    protected async refundAmount(shouldVoid: boolean) {
        this.statusMessage = "Processing refund...";
        const refundOrderArgs = {
            order: this.order!.toModel(),
            shouldVoid,
            lastClientModificationDateTime: this.clientDateTime,
        };

        const jsonResult = await WebHelper.postJsonData("/api/EcomOrder/RefundAmount", refundOrderArgs);
        const result = JSON.parse(jsonResult) as IPaymentResult;

        this.statusMessage = null;
        if (result.successful) {
            await this.showSuccess();
        } else {
            if (this.handleValidationError(result)) {
                return;
            }

            const totalDue = this.totalDueDifference == 0 ? this.order!.totals.orderTotal : this.totalDueDifference;
            await this.refundOrder(totalDue, shouldVoid, result.transactionResponse);
        }
    }

    protected async refund() {
        this.loading = true;
        const refundMoreThanOriginalOrderAmount = this.order!.totals!.orderTotal < 0;
        if (refundMoreThanOriginalOrderAmount) {
            this.refundMoreThanAvailableViewModel = new AsyncInteractionViewModel();
            const result = await this.refundMoreThanAvailableViewModel.interact();
            this.refundMoreThanAvailableViewModel = null;
            this.loading = false;
            return;
        }

        const fullRefund = this.totalDueDifference === 0;
        const isPo = this.order!.isPoOrder;

        const adjTotal = this.order!.adjustments.reduce((amount, x) => amount + x.amount, 0);

        const fullRefundWithAdjustment =
            this.originalOrder!.totals.orderTotal == adjTotal! * -1 || this.order!.totals.orderTotal === 0;

        if (fullRefundWithAdjustment) {
            this.confirmVoidViewModel = new AsyncInteractionViewModel();
            const voidResult = await this.confirmVoidViewModel.interact();
            this.confirmVoidViewModel = null;
            if (voidResult === "cancel") {
                this.loading = false;
                return;
            }

            const shouldVoid = voidResult === "accept";
            await this.refundAmount(shouldVoid);
            this.loading = false;
            return;
        }

        this.confirmRefundViewModel = new ConfirmRefundDialogViewModel();
        this.confirmRefundViewModel.fullRefund = fullRefund;
        const confirmResult = await this.confirmRefundViewModel.interact();
        this.confirmRefundViewModel = null;
        if (confirmResult === "cancel") {
            this.loading = false;
            return;
        }

        await this.refundAmount(fullRefund);

        this.loading = false;
    }

    protected async sendConfirmationEmail() {
        this.emailConfirmationViewModel = new SimpleAsyncInteractionViewModel();
        this.showCustomerEmailConfirmation = true;
        const result = await this.emailConfirmationViewModel.interact();
        this.showCustomerEmailConfirmation = false;
        this.emailConfirmationViewModel = null;
        if (result === "accept") {
            this.statusMessage = "Sending email...";
            await WebHelper.postJsonData("/api/EcomOrder/SendConfirmationEmail", this.order!.toModel());
            this.statusMessage = null;
        }
    }

    protected async onDeleteChargebackClick() {
        this.deleteChargebackViewModel = new SimpleAsyncInteractionViewModel();
        this.showDeleteChargebackDialog = true;
        const result = await this.deleteChargebackViewModel.interact();
        this.showDeleteChargebackDialog = false;
        this.deleteChargebackViewModel = null;
        if (result === "accept") {
            this.statusMessage = "Removing Chargeback...";
            await WebHelper.postJsonData(`/api/EcomOrder/DeleteChargeback/${this.orderId}`);
            this.statusMessage = null;
            this.$emit("chargeback-removed", this.orderId);
        } else {
            return;
        }
    }

    protected async payment() {
        this.loading = true;
        const isComplete = this.order!.isComplete;
        if (!this.order!.isPoOrder && !this.isPromoOrder && !this.isEcertOrder) {
            const dismissViewModel = new SimpleAsyncInteractionViewModel();
            this.decisionDialogViewModel = dismissViewModel;
            this.showDecisionDialogViewModel = true;
            const result = await this.decisionDialogViewModel.interact();
            this.showDecisionDialogViewModel = false;
            this.decisionDialogViewModel = null;

            if (result === "cancel") {
                this.loading = false;
                return;
            }
        }

        this.statusMessage = "Processing payment...";
        const chargeCardArgs = {
            order: this.order!.toModel(),
            lastClientModificationDateTime: this.clientDateTime,
        };

        const jsonResult = await WebHelper.postJsonData("/api/EcomOrder/ChargeCard", chargeCardArgs);
        const result = JSON.parse(jsonResult) as IPaymentResult;

        if (!result.successful && result.transactionResponse && result.transactionResponse.errorCode == "needs_card") {
            this.statusMessage = null;
            await this.chargeOrder(this.totalDueDifference, result.transactionResponse);
            return;
        }

        this.statusMessage = null;
        if (result.successful) {
            if (isComplete) {
                await this.showSuccess();
                return;
            }
            await this.sendConfirmationEmail();
            await this.showSuccess();
        } else {
            if (this.handleValidationError(result)) {
                this.loading = false;
                return;
            }
            await this.showFailure(result, !isComplete);
        }
        this.loading = false;
    }

    protected handleValidationError(result: IPaymentResult) {
        if (result.$typeHint === "LastModificationFailedOrderResult") {
            this.paymentFailure = result;
            this.showOrderValidationFailure = true;
            return true;
        }

        return false;
    }
    protected async chargeOrder(amountDue: number, transactionResponse: ITransactionResponse) {
        this.paymentViewModel = new OrderPaymentViewModel();
        this.paymentViewModel.paymentIntentClientSecret = transactionResponse.paymentIntentClientSecret;
        this.paymentViewModel.paymentPublicApiKey = transactionResponse.paymentPublicApiKey;
        await this.paymentViewModel.initialize();
        this.paymentViewModel.order = this.order;
        this.paymentViewModel.amount = amountDue;
        this.currentView = View.ChargeCard;

        const editResult = await this.paymentViewModel.interact();
        if (editResult === "cancel") {
            this.currentView = View.Order;
        } else if (editResult === "continue") {
            this.statusMessage = "Saving changes...";
            const jsonResult = await WebHelper.postJsonData("/api/EcomOrder/CollectLater", {
                order: this.order!.toModel(),
                lastClientModificationDateTime: this.clientDateTime,
            });

            const result = JSON.parse(jsonResult) as IPaymentResult;
            if (this.handleValidationError(result)) {
                return;
            }

            this.statusMessage = null;
            await this.showSuccess();
        }
    }

    protected async refundOrder(amountDue: number, shouldVoid: boolean, transactionResponse: ITransactionResponse) {
        this.paymentViewModel = new OrderSetupViewModel();
        this.paymentViewModel.paymentIntentClientSecret = transactionResponse.paymentIntentClientSecret;
        this.paymentViewModel.paymentPublicApiKey = transactionResponse.paymentPublicApiKey;
        await this.paymentViewModel.initialize();
        this.paymentViewModel.order = this.order;
        this.paymentViewModel.amount = amountDue;
        this.currentView = View.RefundCard;

        const editResult = await this.paymentViewModel.interact();
        if (editResult === "cancel") {
            this.currentView = View.Order;
        } else if (editResult === "continue") {
            this.statusMessage = "Saving changes...";
            const jsonResult = await WebHelper.postJsonData("/api/EcomOrder/RefundLater", {
                order: this.order!.toModel(),
                lastClientModificationDateTime: this.clientDateTime,
            });
            const result = JSON.parse(jsonResult) as IPaymentResult;
            if (this.handleValidationError(result)) {
                return;
            }

            this.statusMessage = null;
            await this.showSuccess();
        } else {
            this.statusMessage = "Processing refund...";
            const chargeCardArgs = {
                order: this.order!.toModel(),
                shouldVoid: shouldVoid,
                lastClientModificationDateTime: this.clientDateTime,
            };
            const jsonResult = await WebHelper.postJsonData("/api/EcomOrder/RefundAmount", chargeCardArgs);
            const result = JSON.parse(jsonResult) as IPaymentResult;

            this.statusMessage = null;
            if (result.successful) {
                await this.showSuccess();
            } else {
                if (this.handleValidationError(result)) {
                    return;
                }

                this.shouldVoid = shouldVoid;
                await this.showFailure(result);
            }
        }
    }

    protected navigateToOrderManager() {
        if (this.shouldScopeToCatalogBatch)
            window.location.assign(`/ng/ecom-orders.html?batchid=${this.order!.catalogBatchId}`);
        else window.location.assign(`/ng/ecom-orders.html?eid=${this.order!.eventId}`);
    }

    protected editSalesTax() {
        this.editingOrderTax = { ...this.order!.tax };
        this.showTaxSettings = true;
    }

    protected async editShipping() {
        const vm = new OrderShippingViewModel();

        vm.order = this.order;

        if (vm.order?.useBillingAddress) {
            vm.useBillingAddress = true;
        } else {
            vm.useBillingAddress = false;
        }

        this.shippingViewModel = vm;

        const result = await vm.interact();
        if (result.result === "cancel") {
            this.shippingViewModel = null;
            return;
        }

        this.order!.useBillingAddress = result.data.useBillingAddress;
        this.order!.pickupBranchId = result.data.branchId;
        this.order!.organizationBranchId = result.data.organizationBranchId;
        this.order!.totals.shippingTotal = result.data.shippingAmount;
        this.order!.shippingType = result.data.shippingType;
        if (result.data.shippingTypeEnum === ShippingType.Shipping) {
            this.order!.shippingAddress = result.data.address;
        }
        this.shippingViewModel = null;
        await this.reloadOrderTotals();
    }

    protected editHandling() {
        this.showAdjustHandling = true;
    }

    protected async adjustHandlingClosed(accepted: boolean, newValue: number) {
        this.showAdjustHandling = false;
        if (!accepted) {
            return;
        }
        this.order!.totals.handlingTotal = newValue;
        await this.reloadOrderTotals();
    }

    protected async editTaxClosed(accepted: boolean) {
        this.showTaxSettings = false;

        if (accepted) {
            if (this.editingOrderTax?.changeTaxType) {
                for (const item of this.order!.products) {
                    item.selectedTaxType = this.editingOrderTax.selectedTaxType;
                    item.taxRate = this.editingOrderTax.taxRate;
                }
            }
            this.order!.tax = this.editingOrderTax!;
            await this.reloadOrderTotals();
        }
    }
}
