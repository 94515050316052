import {
    AsyncInteractionViewModel,
    CreditCardInfo,
    ICreditCardInfo,
    SimpleAsyncInteractionViewModel,
} from "chipply-common";
import OrderViewModel from "./OrderViewModel";
import EditableViewModel from "@/chipply/EditableViewModel";
import { loadStripe, Stripe, StripeElements } from "@stripe/stripe-js";

export default class OrderPaymentViewModel extends AsyncInteractionViewModel {
    public amount = 0;
    public card: ICreditCardInfo = new CreditCardInfo();
    private _order: OrderViewModel | null = null;

    public stripe: Stripe | null = null;
    public stripeElementsInstance: StripeElements | null = null;

    public paymentIntentClientSecret = "";
    public paymentPublicApiKey = "";

    public loading = true;
    public errorMessage = "";

    public errorViewModel: SimpleAsyncInteractionViewModel | null = null;
    public get showErrorViewModel() {
        return this.errorViewModel != null;
    }

    public async initialize() {
        if (!this.stripe) {
            this.stripe = await loadStripe(this.paymentPublicApiKey);
            this.stripeElementsInstance = this.stripe!.elements({ clientSecret: this.paymentIntentClientSecret });
        }
        this.loading = false;
    }
    public get order() {
        return this._order;
    }

    public set order(value: OrderViewModel | null) {
        if (value) {
            this.card.cardholderName = value.billingAddress.firstName + " " + value.billingAddress.lastName;
        }
        this._order = value;
    }

    public async accept() {
        this.loading = true;
        const { error: stripeConfirmationError } = await this.stripe!.confirmPayment({
            elements: this.stripeElementsInstance!,
            confirmParams: {
                return_url: `${location.origin}/ng/edit-order.html?oid=${this.order?.orderNumber}&flow=charge`,
            },
        });

        this.loading = false;
        if (stripeConfirmationError) {
            const errorViewModel = new SimpleAsyncInteractionViewModel();
            errorViewModel.headerText = "Error";
            errorViewModel.text = stripeConfirmationError.message!;
            this.errorViewModel = errorViewModel;
            await this.errorViewModel.interact();
            this.errorViewModel = null;
            return;
        }

        this.done("accept");
    }
}
