export default class AccountingSummaryToggleOptions {
    public includeAccountingSummaryNotes = true;
    public includeAccountingSummaryOperationalFees = true;
    public includeAccountingSummaryManualAdjustmentsRight = false;
    public includeAccountingSummaryManualAdjustmentsLeft = false;
    public includeAccountingSummaryShowHandlingFees = false;
    public includeHandlingFeesExpanded = false;
    public includeAccountingSummaryPromoCodes = false;
    public includeSalesPersonName = true;
    public includeDealerName = true;
}
