




































































































































































































































































































































































































































































import ScrollableContentMixin from "@/components/ScrollableContentMixin";
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import TextHeading from "@/components/utility/TextHeading.vue";
import LongRunningOperationDialog from "@/components/utility/LongRunningOperationDialog.vue";
import ErrorReporter from "@/components/utility/ErrorReporter.vue";
import chipplyIcons from "@/chipply/ImportIcons";
import { Prop, Watch } from "vue-property-decorator";
import IVuetifyTableOptions from "@/chipply/interface/IVuetifyTableOptions";
import { IListViewModel } from "@/chipply/view-model/IListViewModel";
import CChildPane from "@/components/ui/CChildPane.vue";
import DataTablePagingMixin from "@/components/DataTablePagingMixin";
import { ListPageViewModel } from "@/chipply/view-model/ListPageViewModel";
import { result } from "lodash";
import NavigationSide from "@/components/navigation/NavigationSide.vue";
import { PageViewModel } from "@/chipply/view-model/PageViewModel";
import { EventBus } from "@/chipply/EventBus";
import { NormalizedScopedSlot } from "vue/types/vnode";
import SuccessReporter from "@/components/utility/SuccessReporter.vue";
import CFilterBanner from "../ui/CFilterBanner.vue";

@Component({
    components: {
        TextHeading,
        LongRunningOperationDialog,
        ErrorReporter,
        CChildPane,
        NavigationSide,
        SuccessReporter,
        CFilterBanner,
    },
})
export default class ListLayout extends mixins(ScrollableContentMixin, DataTablePagingMixin) {
    protected get usesSpeedDial(): boolean {
        return true;
    }

    @Prop({
        type: Object,
    })
    public viewModel!: ListPageViewModel;

    @Prop({
        default: false,
        type: Boolean,
    })
    public isLoading!: boolean;

    @Prop({
        default: false,
        type: Boolean,
    })
    public showBack!: boolean;

    @Prop({
        default: true,
        type: Boolean,
    })
    public isHeaderVisible!: boolean;

    @Prop({
        type: Number,
    })
    public containerHeight: number | undefined;

    @Prop({
        default: false,
        type: Boolean,
    })
    public hideDefaultHeader!: boolean;

    @Prop({
        default: "",
        type: String,
    })
    public dataTableNoDataText!: string;

    @Prop({
        default: false,
        type: Boolean,
    })
    public isOptionsButtonVisible!: boolean;

    @Prop({
        default: false,
        type: Boolean,
    })
    public secondOptionsButtonVisible!: boolean;

    @Prop({
        type: Function,
    })
    public optionsAccepted!: () => void;

    @Prop({
        type: Function,
    })
    public optionsCanceled!: () => void;

    @Prop({
        type: Function,
    })
    public optionsDrawerOpened!: () => void;

    @Prop({
        type: Boolean,
        default: false,
    })
    public isNavigationSideVisible!: boolean;

    @Prop({
        default: "",
        type: String,
    })
    public dataTableClass!: string;

    @Prop({
        type: Boolean,
    })
    public noScroll!: boolean;

    @Prop({
        type: Boolean,
    })
    public isChildPaneVisible!: boolean;

    public isSpeedDialVisible = false;
    public isSpeedDialOpen = false;
    public isNavigationSideExpanded = true;

    @Prop({
        default: false,
        type: Boolean,
    })
    public mustSort!: string;

    @Prop({
        default: false,
        type: Boolean,
    })
    public dense!: boolean;

    @Prop({
        default: null,
        type: String,
    })
    public groupBy!: string | null;

    @Prop({
        default: false,
        type: Boolean,
    })
    public groupDesc!: boolean;

    @Prop({
        type: String,
        default: chipplyIcons.mdiDotsVertical,
    })
    public optionsIcon!: string;

    @Prop({
        type: String,
        default: chipplyIcons.mdiDotsVertical,
    })
    public secondOptionsIcon!: string;

    @Prop({ type: Function })
    public itemClass!: any;

    @Prop({
        default: true,
        type: Boolean,
    })
    public isDataTableVisible!: boolean;

    @Prop({
        default: 50,
        type: Number,
    })
    public otherPadding!: number; // the sum of the padding around the heading and search panel

    @Prop({
        type: Boolean,
        default: true,
    })
    public isOptionsDrawerRight!: boolean;

    @Prop({
        default: "",
        type: String,
    })
    public errorMessage!: string | null;

    @Prop({
        default: "",
        type: String,
    })
    public successMessage!: string | null;

    @Prop({
        type: Boolean,
        default: false,
    })
    public showBackOnly!: boolean;

    @Prop({
        default: true,
        type: Boolean,
    })
    public isNavSideInitiallyOpen!: boolean;

    @Prop({
        type: Number,
    })
    public optionsDrawerWidth!: number;

    @Prop({
        default: true,
        type: Boolean,
    })
    public showToggleNavigationButton!: boolean;

    public name = "ListLayout";
    public declare $refs: {
        textHeading: Vue;
        mobileTextHeading: Vue;
        listfooter: HTMLElement;
        searchPanel: HTMLElement;
        layoutWrapper: HTMLElement;
        datatable: Vue;
        filterBannerPanel: HTMLElement;
        navigationArea: Vue;
    };
    public chipplyIcons = chipplyIcons;
    public selectedItem = false;

    public constructor() {
        super();
        this.isNavigationSideExpanded = this.isNavSideInitiallyOpen;
        EventBus.$on("navigation-side-close", (navigationSide: NavigationSide) => {
            this.toggleNavigationSide();
        });
    }

    public toggleNavigationSide() {
        this.isNavigationSideExpanded = !this.isNavigationSideExpanded;
        this.$emit("navigation-side-expanded-changed", this.isNavigationSideExpanded);
    }

    public toggleOptions() {
        this.viewModel.isOptionsDrawerVisible = !this.viewModel.isOptionsDrawerVisible;
        this.$emit("toggle-options");
        if (this.viewModel.isOptionsDrawerVisible) {
            // this.optionsDrawerOpened();
        }
    }

    public toggleSecondaryOptions() {
        this.viewModel.isOptionsDrawerVisible = !this.viewModel.isOptionsDrawerVisible;
        this.$emit("toggle-secondary-options");
    }

    public optionsAccept() {
        this.viewModel.isOptionsDrawerVisible = false;
        if (this.optionsAccepted) {
            this.optionsAccepted();
        }
    }

    public optionsCancel() {
        this.viewModel.isOptionsDrawerVisible = false;
        if (this.optionsCanceled) {
            this.optionsCanceled();
        }
    }

    public raiseBack() {
        this.$emit("back");
    }
    public data() {
        return {};
    }

    public async mounted() {
        await this.$nextTick();
        this.resizeContent();
        const tableWrapper = this.getTableWrapper();
        tableWrapper.addEventListener("scroll", () => {
            this.repositionRowActions();
        });
        if (this.isFilterBannerVisible) {
            this.resizeNavigationArea();
        }
    }

    protected get computedSortBy() {
        let tempString = "";
        if (!this.viewModel.pagination) return "";

        this.viewModel.pagination.sortBy.forEach((element) => {
            tempString += element;
        });
        this.viewModel.pagination.sortDesc.forEach((element) => {
            tempString += element;
        });
        return tempString;
    }

    protected getVariableHeadroom(): number {
        const mobileTableHeader =
            (this.$vuetify.breakpoint.mdAndUp
                ? this.$refs.textHeading?.$el.clientHeight
                : this.$refs.mobileTextHeading?.$el.clientHeight) || 0;
        return (
            mobileTableHeader +
            this.otherPadding +
            (this.$refs.listfooter || { clientHeight: 0 }).clientHeight +
            (this.$refs.filterBannerPanel || { clientHeight: 0 }).clientHeight +
            (this.$refs.searchPanel || { clientHeight: 0 }).clientHeight
        );
    }

    protected getParentHeight(): number {
        if (this.containerHeight) {
            return this.containerHeight;
        }
        return this.height || window.innerHeight;
    }

    protected async selectNextPageCore(): Promise<any> {
        await this.viewModel.list();
    }

    public expandRow(item: any, isExpand: boolean) {
        (this.$refs.datatable as any).expand(item, isExpand);
    }

    public filterSlots(slots: { [p: string]: NormalizedScopedSlot | undefined }) {
        const filteredSlots: { [p: string]: NormalizedScopedSlot | undefined } = {};
        for (let key of Object.keys(slots)) {
            if (key === "rowActionsTemplate") {
                continue;
            }
            filteredSlots[key] = slots[key];
        }
        return filteredSlots;
    }

    public getItemClass(item: any) {
        let classes = "rowStyle ";
        if (this.itemClass && typeof this.itemClass === "function") {
            classes += this.itemClass(item);
        } else if (this.itemClass) {
            classes += this.itemClass;
        }
        return classes;
    }

    public get dataTableHeaders() {
        if (!this.viewModel) return [];
        let headers = [];
        headers.push(...this.viewModel.dataTableHeaders);
        headers.push({
            text: "",
            value: "rowActionsTemplate",
            sortable: false,
            cellClass: "rowActionClass",
        });
        return headers;
    }

    public get isFilterBannerVisible() {
        if (this.viewModel) {
            return Object.keys(this.viewModel.filtersViewModel.filterItems).length > 0;
        }
        return false;
    }

    public get filtersApplied() {
        if (this.viewModel) {
            return this.viewModel.filtersViewModel.appliedFilters.length;
        }
        return 0;
    }

    @Watch("filtersApplied")
    public delayResizeNavigationArea() {
        this.$nextTick(() => this.resizeNavigationArea());
        this.$nextTick(() => this.resizeContent());
    }

    public resizeNavigationArea() {
        const navArea = this.$refs.navigationArea.$el as HTMLElement;
        const filterBannerPanel = this.$refs.filterBannerPanel;
        const offset = filterBannerPanel.offsetHeight + 150;
        navArea.style.height = `calc(100vh - ${offset}px)`;
    }

    public delayResize() {
        this.$nextTick(() => this.resizeContent());
    }

    @Watch("computedSortBy")
    protected async onSortChanged() {
        if (this.viewModel?.dataTableDisableSort) {
            return;
        }

        await this.viewModel.list(true);
    }

    @Watch("viewModel.errorMessage")
    protected onErrorMessageChanged() {
        if (this.viewModel.errorMessage != null) {
            this.viewModel.showErrorMessage = true;
        } else {
            this.viewModel.showErrorMessage = false;
        }
    }

    @Watch("viewModel.successMessage")
    protected onSuccessMessageChanged() {
        if (this.viewModel.successMessage != null) {
            this.viewModel.showSuccessMessage = true;
        } else {
            this.viewModel.showSuccessMessage = false;
        }
    }

    @Watch("viewModel.items")
    protected async itemsChanged() {
        await this.$nextTick();
        this.repositionRowActions();
    }

    @Watch("$vuetify.breakpoint.name")
    protected async breakpointChanged() {
        await this.$nextTick();
        this.repositionRowActions();
    }

    @Watch("isNavigationSideExpanded")
    protected async navigationSideChanged() {
        // wait .5 seconds for side nav open/close animation to complete
        setTimeout(() => {
            this.repositionRowActions();
        }, 500);
    }

    public resizeElements = () => {
        this.resizeContent();
        this.repositionRowActions();
    };

    protected getTableWrapper() {
        return this.$refs.datatable.$el.children[0] as HTMLElement;
    }

    /**
     * Repositions the row actions elements on the table to the right hand of the viewing pane,
     * in context of scroll position
     * @function repositionRowActions
     * @returns {void}
     */
    public repositionRowActions = (): void => {
        // TODO: Double check this code after Vue 3 upgrade
        if (!this.$refs.datatable) {
            return;
        }
        const tableWrapper = this.getTableWrapper();
        const rowActionsElements = tableWrapper.querySelectorAll(".rowActions");
        const rightOfViewingPane = tableWrapper.scrollWidth - tableWrapper.offsetWidth - tableWrapper.scrollLeft + 20; //adding 20 to remain consistent instead of margin
        const rightPosition = rightOfViewingPane + "px";
        const tableTopPosition = tableWrapper.getBoundingClientRect().top;

        for (let ra of rowActionsElements) {
            (ra as HTMLElement).style.right = rightPosition;
            // hide row actions when they are too close to the top of the table and can no longer be hovered by the user
            const rowTopPosition = (ra as HTMLElement).parentElement!.getBoundingClientRect().top;
            (ra as HTMLElement).style.visibility = rowTopPosition < tableTopPosition + 30 ? "hidden" : "visible";
        }
        this.$emit("row-actions-repositioned", rightPosition, tableTopPosition, tableWrapper.scrollTop);
    };

    public itemSelected($event: any) {
        EventBus.$emit("resize-content");
        this.$emit("item-selected", $event);
    }
    public toggleSelectAll($event: any) {
        EventBus.$emit("resize-content");
        this.$emit("toggle-select-all", $event);
    }

    public errorReporterChange(nv: boolean) {
        this.$emit("error-reporter-change", nv);
    }

    public successReporterChange(nv: boolean) {
        this.$emit("success-reporter-change", nv);
    }

    public getCloseIcon() {
        if (this.showBack) {
            return "$iconArrowBack";
        } else {
            return "$iconDashboardSolid";
        }
    }

    public getMobileCloseIcon() {
        if (this.showBack) {
            return "$iconArrowBack";
        } else {
            return "$iconClose";
        }
    }

    public closeClick() {
        if (this.showBack || this.showBackOnly) {
            this.raiseBack();
        }
        (document.activeElement as HTMLElement).blur();
    }

    public async refreshData() {
        await this.viewModel.list(true);
    }
}
