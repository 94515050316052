
















































































































































































































































































import { Component, Vue, Prop, Provide } from "vue-property-decorator";
import Validation from "@/validation";
import chipplyIcons from "@/chipply/ImportIcons";
import { ITextValue } from "chipply-common";
import CTextButton from "../ui/CTextButton.vue";
import OrderManagerViewModel from "@/chipply/ecom-orders/OrderManagerViewModel";
import { EventBus } from "@/chipply/EventBus";
import { mask } from "vue-the-mask";
import EcomOrderTreeSelector from "../input/EcomOrderTreeSelector.vue";

@Component({
    components: {
        CTextButton,
        EcomOrderTreeSelector,
    },
    directives: {
        mask,
    },
})
export default class EcomOrdersFiltersEditor extends Vue {
    @Prop({ required: true }) public vm!: OrderManagerViewModel;
    @Prop({ required: true }) public isAdmin!: boolean;
    @Provide() filtersViewModel = this.vm.filtersViewModel;

    public Validation = Validation;
    public chipplyIcons = chipplyIcons;
    public orderFromDateMenu = false;
    public orderToDateMenu = false;
    public estShipDateMenu = false;

    public get dealerIdsNumberValue(): number[] {
        const selectedDealerIds = this.vm.filtersViewModel.filterItems.dealerIds?.selected ?? [];

        return Array.isArray(selectedDealerIds)
            ? selectedDealerIds.map((dealer: ITextValue<number>) => dealer.value)
            : [];
    }

    public mounted() {
        EventBus.$on("resetFilters", this.resetFilters);
    }

    public beforeDestroy() {
        EventBus.$off("resetFilters", this.resetFilters);
    }

    public resetFilters() {
        this.vm.filtersViewModel.clearAll();
        this.vm.list(true);
    }

    public collapseAbovePanels() {
        const currentPanelIndex = 3;

        this.vm.panel = this.vm.panel.filter((index: number) => index >= currentPanelIndex);

        if (!this.vm.panel.includes(currentPanelIndex)) {
            this.vm.panel.push(currentPanelIndex);
        }
    }
}
